import React, { useState, createContext, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, GetProgramAccountsFilter } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

export const PlaylistCollectionMembershipContext = createContext();

const PlaylistCollectionMembershipProvider = ({ collectionMintAddresses, children }) => {
    const ownerPublicKey = useWallet().publicKey;
    const [nftMintAddresses, setNftMintAddresses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const endpoint = process.env.REACT_APP_RPC_ENDPOINT;
    useEffect(() => {
        const fetchTokenAccounts = async () => {
            const solana = new Connection(endpoint);

            const filters = [
                { dataSize: 165 },
                {
                    memcmp: {
                        offset: 32,
                        bytes: ownerPublicKey?.toString() || "",
                    }
                }
            ];

            const accounts = await solana.getParsedProgramAccounts(TOKEN_PROGRAM_ID, { filters });

            const mintAddresses = accounts.map(account => account.account.data.parsed?.info?.mint).filter(Boolean);
            const collectionIDs = accounts.map(account => account.account.data);
            setNftMintAddresses(mintAddresses);
            setIsLoading(false);
            //NFTs in Users Wallet
            console.log("Collection IDs: " + collectionIDs + '\n');
            console.log(JSON.stringify(collectionIDs));
            console.log("Wallet MintAddresses: " + mintAddresses + '\n');

            //NFTs in the database
            console.log("Collection MintAddresses: " + collectionMintAddresses + '\n');
        };

        if (ownerPublicKey) {
            fetchTokenAccounts();
        }
    }, [ownerPublicKey]);

    if (isLoading) {
        return <p>Connect your Solana wallet to view content</p>;
    }

    return (
        <PlaylistCollectionMembershipContext.Provider value={{ nftMintAddresses, collectionMintAddresses }}>
            {children}
        </PlaylistCollectionMembershipContext.Provider>
    );
};

export default PlaylistCollectionMembershipProvider;
